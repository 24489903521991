const initAnalytics = id => {
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', id);
    const script = document.createElement('script');
    script.setAttribute("type", "text/javascript");
    script.setAttribute("src", `https://www.googletagmanager.com/gtag/js?id=${id}`);
    document.head.appendChild(script);
}

const id = 'UA-45843646-2';
const consent = localStorage.getItem('cookie-consent')

if (consent === null) {
    [...document.querySelectorAll('.cookie-warning')].forEach(el => {
        el.classList.remove('dn');
        el.querySelector('.cookie-warning__yes').addEventListener('click', () => {
            localStorage.setItem('cookie-consent', true);
            el.classList.add('dn');
            initAnalytics(id)
        })
        el.querySelector('.cookie-warning__no').addEventListener('click', () => {
            localStorage.setItem('cookie-consent', false);
            el.classList.add('dn');
        })
    })
} else if (consent === "true") {
    initAnalytics(id)
}