const hash = url => url.split('#')[ 1 ];

[ ...document.querySelectorAll('.kirbytext') ].forEach( container => {
    const refs = [ ...container.querySelectorAll( 'sup[id^="fnref"]' ) ];
    refs.forEach( ref => {
        const a = ref.querySelector('a')
        const id = hash( a.href ).replace( ':', "\\:")
        const li = container.querySelector( '#' + id );
        const footnote = document.createElement( 'span' );
        [ ...li.children ].forEach( p => {
            const span = document.createElement( 'span' );
            span.innerHTML = p.innerHTML;
            footnote.appendChild( span );
        })
        const backref = footnote.querySelector('.footnote-backref');
        backref.parentNode.removeChild( backref );
        footnote.className = 'inline-footnote dn'
        ref.parentNode.insertBefore( footnote, ref.nextSibling );
        a.addEventListener( 'click', e => {
            e.preventDefault();
            e.stopPropagation();
            a.classList.toggle('footnote-ref--active')
            footnote.classList.toggle('dn');
        })
    })
    const footnotes = container.querySelector('.footnotes');
    if ( footnotes ) footnotes.parentNode.removeChild( footnotes );
})