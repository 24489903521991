document.body.style.opacity = 0;
setTimeout( () => {
    document.body.style.transition = 'opacity .5s';
    document.body.style.opacity = 1
}, 10 );

[ ...document.querySelectorAll('a[href]:not([target="_blank"]):not([href^="#"])') ].forEach( a => {
    a.addEventListener( 'click', e => {
        e.preventDefault();
        document.body.style.opacity = 0;
        document.body.style.transition = 'opacity .25s';
        setTimeout(() => window.location.href = a.href, 250 );
    })
})

// https://gomakethings.com/fixing-safaris-back-button-browser-cache-issue-with-vanilla-js/
window.onpageshow = function(event) {
    if (event.persisted) {
        document.body.style.transition = 'opacity .5s';
        document.body.style.opacity = 1
    }
};