const loadImage = img => new Promise( resolve => {
    if ( img.srcset ) return resolve();
    img.onload = resolve;
    img.srcset = img.dataset.srcset;
});

const loadImages = ( images, i = 0 ) => {
    if ( i >= images.length ) return;
    loadImage( images[ i ] ).then( () => loadImages( images, i + 1 ) )
}

[ ...document.querySelectorAll('.slider') ].forEach( container => {
    const slides = [ ...container.children ];
    const links = [ ...document.querySelectorAll('.menu-link') ]
    let curr = -1;
    const goto = i => {
        slides.forEach( ( slide, idx ) => {
            if ( i === idx ) {
                slide.style.transition = 'opacity .2s';
                slide.style.opacity = 1;
                if ( !slide.srcset ) slide.srcset = slide.dataset.srcset;
            } else {
                slide.style.transition = 'opacity .2s';
                slide.style.opacity = 0;
            }
        })
        links.forEach( ( link, idx ) => {
            link.classList.toggle( 'menu-link--active', i === idx );
        })
        curr = i;
    }
    links.forEach( ( el, idx ) => {
        el.addEventListener( 'mouseenter', () => {
            stop();
            goto( idx )
        })
        el.addEventListener( 'mouseleave', () => {
            goto( idx )
            play();
        })
    })
    let timer = null;
    const play = () => {
        timer = setTimeout( () => {
            goto( ( curr + 1 ) % slides.length )
            play();
        }, 5000 );
    }
    const stop = () => clearTimeout( timer );
    loadImages( slides );
    goto( 0 );
    play();
})