import { slideUp, slideDown } from 'es6-slide-up-down';

[ ...document.querySelectorAll('.show-more') ].forEach( container => {
    const content = container.querySelector('.show-more__content');
    const toggle = container.querySelector('.show-more__toggle')
    let isVisible = false;
    toggle.addEventListener( 'click', e => {
        e.preventDefault();
        if ( isVisible ) {
            slideUp( content, { duration: 200 } );
            toggle.classList.remove( 'show-more__toggle--up' )
            // toggle.innerText = 'Show more';
            isVisible = false;
        } else {
            slideDown( content, { duration: 200 });
            toggle.classList.add( 'show-more__toggle--up' )
            // toggle.innerText = 'Show less';
            isVisible = true;
        }
    });
    content.style.height = 0;
    // slideUp( content, { duration: 0 });
    // $( content ).slideUp(0);
})