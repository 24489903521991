[ ...document.querySelectorAll('.notes') ].forEach( container => {
    const refs = {};
    [ ...container.querySelectorAll('.note-ref') ].forEach( el => {
        const text = el.innerText.toLowerCase();
        if ( !refs[ text ] ) refs[ text ] = [];
        refs[ text ].push( el );
    })
    for ( let key in refs ) {
        const els = refs[ key ];
        els.forEach( el => {
            el.addEventListener('mouseenter', () => {
                els.forEach( el => el.classList.add( 'note-ref--active' ) )
            })
            el.addEventListener('mouseleave', () => {
                els.forEach( el => el.classList.remove( 'note-ref--active' ) )
            })
            el.addEventListener( 'click', () => {
                if ( !( 'ontouchstart' in window ) ) return;
                const other = els.find( el2 => el2 !== el );
                if ( !other ) return;
                const rect = other.getBoundingClientRect();
                window.scrollBy({
                    left: 0,
                    top: rect.top - window.innerHeight / 2 + rect.height / 2,
                    behavior: 'smooth'
                });
            })
        })
    }
})